import React, { useRef } from "react";
import "./Services.css";
import { FaSearch } from "react-icons/fa";
import bgimage from "../Assets/masatv.png";
import bgimg from "../Assets/masatvimg.png";
import cyberimg from "../Assets/cybersecurity_image.png";
import cloudimg from "../Assets/cloud_computing_image.png";
import designimg from "../Assets/successimg4.jpg";
import testingimg from "../Assets/testingimg.jpg";
const Services = () => {
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="services_outside_container">
      <div className="hero_section_services">
        <div className="hero_overlay_panel">
          <h1 className="herder_text">TAKE A LOOK AT OUR SERVICES</h1>
          <p>
            We create beautiful design & great user experience, to supply the
            best digital products for our clients. Here are our projects.
          </p>
          <button onClick={() => handleClick()} className="button_watch_here">
            Watch Here
          </button>
        </div>
      </div>
      <div className="services_container_style" ref={ref}>
        <h1>Our services</h1>
        <div className="cards_container_style">
          <div className="service_card">
            <div className="image_wrapper">
              <img src={bgimage} alt="" className="image_style" />
            </div>
            <div className="service_card_content">
              <h2>Web Development</h2>
              <p>
                With solid expertise and wide experience in website development.
                Making the best out of rest is what makes us different. Websites
                are the front face of a company so by reflecting your work with
                attractive and engaging users .
              </p>
            </div>
          </div>
          <div className="service_card">
            <div className="image_wrapper">
              <img src={bgimg} alt="" className="image_style" />
            </div>
            <div className="service_card_content">
              <h2>Mobile Applications</h2>
              <p>
                We create mobile applications in Android platform and iOS
                platform for all the devices out there. Our applications are
                secure, advanced, interactive and user friendly.
              </p>
            </div>
          </div>
          <div className="service_card">
            <div className="image_wrapper">
              <img src={designimg} alt="" className="image_style" />
            </div>
            <div className="service_card_content">
              <h2>Graphic & Web Design</h2>
              <p>
                We design logo, Infographics, web banners, advertisement banners
                with high quality suitable for various types of uses. We design
                websites that are attractive and responsive across all devices.
              </p>
            </div>
          </div>
          <div className="service_card">
            <div className="image_wrapper">
              <img src={cloudimg} alt="" className="image_style" />
            </div>
            <div className="service_card_content">
              <h2>Data Analytics</h2>
              <p>
                A data analytics company, Masa helps businesses from 10+
                industries integrate, aggregate, and analyze various data types
                from multiple data sources to address their most ambitious needs
                at department and enterprise levels.
              </p>
            </div>
          </div>
          <div className="service_card">
            <div className="image_wrapper">
              <img src={testingimg} alt="" className="image_style" />
            </div>
            <div className="service_card_content">
              <h2>Testing and QA</h2>
              <p>
                In software testing services, Masa has built testing expertise
                in healthcare, manufacturing, retail, wholesale, logistics, and
                other industries. Our goal-driven self-managed testing experts
                can quickly dive into your project (within 1-3 days) and
                validate every aspect of your software: functionality,
                integrations, performance, usability, and security.
              </p>
            </div>
          </div>
          <div className="service_card">
            <div className="image_wrapper">
              <img src={cyberimg} alt="" className="image_style" />
            </div>
            <div className="service_card_content">
              <h2>Cybersecurity</h2>
              <p>
                our users and making them navigate through security features,
                site architecture with visuals, links and making their
                experience Lag and Hassle free, we help in building every bit
                while you climb higher on the ladder of growth.Our applications
                are secure, advanced, interactive and user friendly.we help
                minimising cyberrisks and avoid devastating consequences of
                cyber attacks
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="services_input_container">
        <p>Type here what you're looking for</p>
        <div className="services_input_type">
          <input
            type="text"
            className="services_input_text"
            placeholder="search"
          />
          <FaSearch size={24} color="blue" />
        </div>
      </div>
    </div>
  );
};

export default Services;
