import React from "react";

import "./Products.css";

import image from "../Assets/masatvimg.png";
import image1 from "../Assets/products_img.png";
import image2 from "../Assets/products_Analytics.png";
import image3 from "../Assets/Conversational_AI_img.png";
import image4 from "../Assets/cloudcomputing_environment_img.png";

const Products = () => {
  return (
    <div className="product_container">
      <div className="products_hero_section">
        <div className="product_hero_container">
          <h1>Our Products</h1>
          <p>
            MASA is graded as the leading Software web design company in India,
            we also have a full-fledged product division where we have built
            some highly successful products that are being used by thousands of
            customers across the globe. We have more than 10 products across
            different verticals and a bunch of brands that cater to segments
            like Web development, eCommerce, E-Learning, SEO, Support and
            Maintenance, Training, etc.
          </p>
        </div>
      </div>

      <div className="product_grid_container">
        <div className="grid_content_container">
          <div className="capsule_button">
            <p>MASA</p>
          </div>
          <h2>OTT/IPTV Middleware management</h2>
          <p>
            MASA is one of the leading Software app development companies in the
            world. MASA is a complete ott/iptv middleware for delivering
            tv,vod,DVR,catchup and interactive service to customers in any IP
            based network. masa company with 10+ pro products and a customer
            base of more than 1000+ active customers, many of which are leading
            in their own categories and have an overall rating of 4.3/5.
            Currently, our portfolio includes 15 Web development and 2 Mobile
            Apps.
          </p>
          <button className="visit_button">Visit Site</button>
        </div>
        <div className="product_image_wrapper">
          <img src={image} alt="" className="product_image_style" />
        </div>
      </div>

      <div className="product_grid_container">
        <div className="product_image_wrapper">
          <img src={image1} alt="" className="product_image_style" />
        </div>
        <div className="grid_content_container">
          <div className="capsule_button">
            <p>DIGITAL TRANSFORMATION</p>
          </div>
          <h2> Company with Alumni</h2>
          <p>
            Masa is a training vertical of IPTV and since 2014, we have trained
            more than 1000 students in Digital Marketing and various Programming
            Languages. MASA possesses an overall rating of 4.99 in public from
            our students and a near-100% placement record in the past 7 years.,
            through our groundbreaking training methods.
          </p>
          <button className="visit_button">Visit Site</button>
        </div>
      </div>
      <div className="product_grid_container">
        <div className="grid_content_container">
          <div className="capsule_button">
            <p className="capsule_sub">HEALTHCARE</p>
          </div>
          <h2> HEALTHCARE DEVELOPMENT</h2>
          <p>
            As healthcare businesses are expanding, handling more clients, and
            dealing with stringent regulatory requirements, they require their
            resource utilization to be optimal and focus on innovation and
            growth. Enabling them in this is Robotic Process Automation, which
            can help to automate repetitive back-end processes and workflows,
            both fundamental and complex, such as claims and process management.
          </p>
          <button className="visit_button">Visit Site</button>
        </div>
        <div className="product_image_wrapper">
          <img src={image2} alt="" className="product_image_style" />
        </div>
      </div>
      <div className="product_grid_container">
        <div className="product_image_wrapper">
          <img src={image3} alt="" className="product_image_style" />
        </div>
        <div className="grid_content_container">
          <div className="capsule_button">
            <p>Artifical Intelligence</p>
          </div>
          <h2>Conversational AI from MASA</h2>
          <p>
            Chatbots and Chatbots and conversational AI have been around for
            quite some time now. With Covid-19, the demand has increased further
            due to the easy omnichannel deployment and reduced cost of
            development of these AI-powered customer support services. They
            enable businesses to stay connected and increase customer
            engagement, provide 24/7 customer support, and meet customer demand
            for … have been around for quite some time now. With Covid-19, the
            demand has increased further due to the easy omnichannel deployment
            and reduced cost of development of these AI-powered customer support
            services. They enable businesses to stay connected and increase
            customer engagement, provide 24/7 customer support, and meet
            customer demand for
          </p>
          <button className="visit_button">Visit Site</button>
        </div>
      </div>
      <div className="product_grid_container">
        <div className="grid_content_container">
          <div className="capsule_button">
            <p>Cloud App Development Company</p>
          </div>
          <h2>Multi-Cloud Security and Compliance</h2>
          <p>
            The overall market size for Multi Cloud Management technology is
            expected to grow at a CAGR of 30.8%, from USD 2250.1 million in 2020
            to USD 19278.2 Million by 2028. This is because it enables
            businesses to use a single interface to manage and monitor
            applications and workloads across the different public clouds they
            are hosted on. Businesses especially need to manage workloads that
            commonly migrate between clouds and use tools and processes for the
            purpose.
          </p>
          <button className="visit_button">Visit Site</button>
        </div>
        <div className="product_image_wrapper">
          <img src={image4} alt="" className="product_image_style" />
        </div>
      </div>
    </div>
  );
};

export default Products;
