import "./Home.css";

import { GiQuickSlash } from "react-icons/gi";
import { GiLevelThreeAdvanced } from "react-icons/gi";

import { IoArrowForward } from "react-icons/io5";
import { FaLock } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";

const Home = () => {
  return (
    <div className="home_main_container">
      <div className="home_hero_container">
        <h1 className="header_text_home">
          Software Consulting and Development
        </h1>
        <h2>We Action encourage digital success</h2>
      </div>
      <div className="home_solutions_container">
        <h2 className="home_delivery_solutions">Solutions We Deliver</h2>
        <h3 className="home_delivery_solutions">
          We IT-enable all kinds of B2B, B2C interactions and internal
          operations.
        </h3>
        <div className="grid_box_container">
          <div className="grid_box">
            <p>Enterprise application</p>
            <div className="icon">
              {<IoArrowForward size={16} /*color="blue" */ />}
            </div>
          </div>
          <div className="grid_box">
            <p>Web development</p>
            <div className="icon">
              <IoArrowForward size={16} />
            </div>
          </div>
          <div className="grid_box">
            <p>CRM</p>
            <div className="icon">
              <IoArrowForward size={16} />
            </div>
          </div>
          <div className="grid_box">
            <p>HR software</p>
            <div className="icon">
              <IoArrowForward size={16} />
            </div>
          </div>
          <div className="grid_box">
            <p>eLearning software</p>
            <div className="icon">
              <IoArrowForward size={16} />
            </div>
          </div>
          <div className="grid_box">
            <p>eCommerce</p>
            <div className="icon">
              <IoArrowForward size={16} />
            </div>
          </div>
          <div className="grid_box">
            <p>Mobile Apps</p>
            <div className="icon">
              <IoArrowForward size={16} />
            </div>
          </div>
          <div className="grid_box">
            <p>Cybersecurity</p>
            <div className="icon">
              <IoArrowForward size={16} />
            </div>
          </div>
          <div className="grid_box">
            <p> management software</p>
            <div className="icon">
              <IoArrowForward size={16} />
            </div>
          </div>

          <div className="grid_box">
            <p>Data Analytics</p>
            <div className="icon">
              <IoArrowForward size={16} />
            </div>
          </div>
          <div className="grid_box">
            <p>Web Portals</p>
            <div className="icon">
              <IoArrowForward size={16} />
            </div>
          </div>
          <div className="grid_box">
            <p>Graphics and webdesign</p>
            <div className="icon">
              <IoArrowForward size={16} />
            </div>
          </div>
        </div>
      </div>
      <div className="home_body_info_para">
        <h1>Why us</h1>
        <p>
          At MASA, We believe there is a better way to bring value to your
          business. More valuable, less invasive way where customers are earned
          rather than bought. We are obsessively passionate about it, and our
          mission is to help people achieve it.
        </p>
      </div>
      <div className="home_body_container">
        <div className="home_body_card">
          <FaLock size={45} color="#063e5f" />
          <h1>Secure</h1>
          <p>
            We install and setup SSL certificates for encryption and Captchas to
            avoid spam and attacks. .
          </p>
        </div>
        <div className="home_body_card">
          <GiQuickSlash size={45} color="#063e5f" />
          <h1>Faster</h1>
          <p>
            We optimize code to perform faster. We tune the server for quicker
            delivery of the content and use Caching.
          </p>
        </div>
        <div className="home_body_card">
          <GiLevelThreeAdvanced size={45} color="#063e5f" />
          <h1>Advanced</h1>
          <p>
            We use future proof advanced technologies, latest standards and
            smart coding techniques.
          </p>
        </div>
        <div className="home_body_card">
          <GiLevelThreeAdvanced size={45} color="#063e5f" />

          <h1>Great Support</h1>
          <p>
            We work hard to keep our customers worry free and help them to focus
            on business.
          </p>
        </div>
        <div className="home_body_card">
          <FaUser size={45} color="#063e5f" />
          <h1>Best UI/UX</h1>
          <p>
            Our UI/UX are designed for providing best user experience and
            satisfaction across all devices and OS.
          </p>
        </div>
        <div className="home_body_card">
          <FaMobileAlt size={45} color="#063e5f" />
          <h1>Responsive</h1>
          <p>
            Our websites and applications are build on responsive frameworks,
            which display content based on device.
          </p>
        </div>
      </div>
      <div className="home_stories_container">
        <h1>Success Stories</h1>

        <div className="grid_stories_1">
          <div className="one">
            <p>Masa owns End to end service. No hassle.</p>
          </div>
          <div className="two">
            <p>Every tecnical thing is handled by Masa.I'm grateful</p>
          </div>
        </div>
        <div className="grid_stories_2">
          <div className="three">
            <p>
              We are delighted with the site , We are happy with the great
              support they are providing
            </p>
          </div>
          <div className="four">
            <p>Modernization of SaaS Gaming Platform to SOA</p>
          </div>
          <div className="five">
            <p>
              Managed Testing Services for US Multi-Industry Corporation QA AND
              SOFTWARE TESTING
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
