const MenuItems = [
  {
    title: "Health Management",
    path: "/solutions/healthmanagement",
  },
  {
    title: "ERP",
    path: "/solutions/erp",
  },
  {
    title: "Operations Management",
    path: "/solutions/operationsmanagement",
  },
  {
    title: "File Management",
    path: "/solutions/filemanagement",
  },
  {
    title: "Financial Management",
    path: "/solutions/financialmanagement",
  },
  {
    title: "Payment Management",
    path: "/solutions/paymentmanagement",
  },
  {
    title: "Marketing and Advertising",
    path: "/solutions/marketing and advertising",
  },
  {
    title: "Data Analysis",
    path: "/solutions/data analysis",
  },
  {
    title: "HumanResources",
    path: "/solutions/humanresources",
  },
  {
    title: "Content Management",
    path: "/solutions/contentmanagement",
  },
  {
    title: "Web Portals",
    path: "/solutions/webportals",
  },
];

export default MenuItems;
