import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import Aboutus from "./Components/Aboutus/Aboutus";
import Products from "./Components/Products/Products";
import Services from "./Components/Services/Services";
import Solutions from "./Components/Solutions/Solutions";
import Contactus from "./Components/Contactus/Contactus";
import Technologies from "./Components/Technologies/Technologies";
import HealthManagement from "./Components/Solutions/HealthManagement";

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/Aboutus" element={<Aboutus />}></Route>
        <Route path="/products" element={<Products />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/solutions" element={<Solutions />}></Route>
        <Route path="/technologies" element={<Technologies />}></Route>
        <Route path="/contactus" element={<Contactus />}></Route>
        <Route
          path="/solutions/healthmanagement"
          element={<HealthManagement />}
        />
        {/* <Route
          path="/solutions/erp"
          element={<ERP/>}
        />
         <Route
          path="/solutions/operationsmanagement"
          element={<HealthManagement />}
        />
         <Route
          path="/solutions/healthmanagement"
          element={<OperationsManagement />}
        />
         <Route
          path="/solutions/healthmanagement"
          element={<HealthManagement />}
        /> */}
      </Routes>
      <div className="home_footer">
        <p>© Copyright MASAPLAYER.COM All Rights Reserved</p>
        <p>Designed by SAZPIN SOFTWARE</p>
      </div>
    </Router>
  );
};

export default App;
