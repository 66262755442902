import React from "react";
import "./Aboutus.css";
import image from "../Assets/aboutus_bgimage.jpg";
import { FaCircleCheck } from "react-icons/fa6";
import { FaQuoteLeft } from "react-icons/fa6";
import { FaQuoteRight } from "react-icons/fa6";

const Aboutus = () => {
  return (
    <div className="about_main_container">
      <div className="about_position_container">
        <div className="about_hero_container">
          <h1>About Us</h1>
          <p>Welcome to MASA</p>
          <p>
            Founded by a team of Industry Veterans with 10+ years of cumulative
            experience in technology, business and strategy.
          </p>
        </div>
        <div className="cards_abot_container">
          <div className="card_about_page">
            <h1>Who Are We</h1>
            <p>
              MASA is a new-age business and technology consulting firm founded
              by industry veterans. Our focus is to design and deliver Software
              solutions by paying careful attention to our customers’ business
              needs and expected strategic outcomes.
            </p>
          </div>
          <div className="card_about_page">
            <h1>Our Approach</h1>
            <p>
              Our primary expertise revolves around our ability to deliver
              seamless digital transformation solutions and cloud services,
              while keeping in mind our customers’ constraints and budgets.
              We’re headquartered in hyderabad,telangana.
            </p>
          </div>
        </div>
      </div>

      <div className="about_grid_containner">
        <div className="grid_about_content_container">
          <h2>All What You Need To Know About Our Company</h2>
          <p>
            MASA is established in the year 2014 at chennai in Tamil Nadu,
            India. We are one of the Topmost Software development company in
            chennai. We have the best team of experts to create all our projects
            and 10+ years of experience in the Industry. We design and maintain
            our websites according to the Customer’s needs and budget. We do all
            kinds of Web Design, Web Development, Software Development, Mobile
            App Development, WooCommerce Services, and Digital Marketing. We
            take pride in saying that we have completed more than 1000+ projects
            till now. MASA Understand and value their client’s time, so we
            provide the best services in meantime, our support stay with client
            24 * 7. The project team consists of full-time resources like…
          </p>
        </div>
        <div className="about_image_wrapper">
          <img src={image} alt="" className="about_image_style" />
        </div>
      </div>
      <div className="_about_icon_main_container">
        <div className="icon_about_container">
          <FaCircleCheck size={22} color="#063e5f" />
          <p>Analysts</p>
        </div>
        <div className="icon_about_container">
          <FaCircleCheck size={22} color="#063e5f" />
          <p>Designers</p>
        </div>
        <div className="icon_about_container">
          <FaCircleCheck size={22} color="#063e5f" />
          <p>Programmers</p>
        </div>
        <div className="icon_about_container">
          <FaCircleCheck size={22} color="#063e5f" />
          <p>Testers</p>
        </div>
        <button className="button_about_us">Read more</button>
      </div>
      <div className="experiance_grid_container">
        <div className="projects_grid">
          <h1 className="headerText">1000 +</h1>
          <p>Projects</p>
        </div>
        <div className="projects_grid">
          <h1 className="headerText">300 +</h1>
          <p>Happy Clients</p>
        </div>
        <div className="projects_grid">
          <h1 className="headerText">10 +</h1>
          <p>Years of Experience</p>
        </div>
      </div>
      <div className="client_feedback_container">
        <h4>CLIENT FEEDBACK</h4>
        <div className="client_container">
          <h2>What Our Clients Say</h2>
          <p>
            <FaQuoteLeft color="navy" />
            Over the past ten years, iptv was very pleased with the service
            provided by Masa development teams and executive management.
            <FaQuoteRight color="navy" />
          </p>
          <p>dr.Sivakumar,CEO texas technology</p>
        </div>
        <div className="client_container">
          <h2>What Our Clients Say</h2>
          <p>
            <FaQuoteLeft color="navy" />
            Our Mission Is To Become The Most Ethical Company With Top-Notch
            Quality.
            <FaQuoteRight color="navy" />
          </p>
          <p>Suryakumar.p</p>
          <p>SV Tech innovations pvt.ltd</p>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
