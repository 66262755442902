import React from "react";
import "./Solutions.css";
import { FaSearch } from "react-icons/fa";

const Solutions = () => {
  return (
    <div className="outside_container">
      <div className="main_container">
        <div className="solutions_container_head">
          <h1>Solutions</h1>
          <p>
            We build on the IT domain expertise and industry knowledge to design
            sustainable technology solutions.
          </p>
        </div>
        <div className="solutions_container">
          <div className="solutions_container_head">
            <h3>Solutions</h3>

            <p>Web development</p>
            <p>Operations Management</p>
            <p>Financial Management</p>
            <p>Supply Chain Management</p>
            <p>Asset Management</p>
            <p>cyber security</p>
            <p>Data Analytics</p>
            <p>Human Resources</p>
            <p>eLearning</p>
          </div>
          <div className="solutions_container_head">
            <p> CRM</p>
            <p> Marketing & Advertising</p>
            <p> Ecommerce</p>
            <p> Kiosk Software</p>
            <p> Web Portals</p>
            <p> Document Management</p>
            <p> Content Management</p>
            <p> Project Management</p>
          </div>
          <div className="solutions_container_head">
            <h3>Industries</h3>
            <p>Healthcare</p>
            <p>Banking, Financial Services, and Insurance</p>
            <p>Manufacturing</p>
            <p>Professional Services</p>
            <p>Retail</p>
            <p>Transportation and Logistics</p>
            <p>Telecommunications</p>
            <p>Oil & Gas</p>
            <p>Construction</p>
          </div>
        </div>
      </div>
      <div className="solutions_input_container">
        <p>Type here what you're looking for</p>
        <div className="input_type">
          <input type="text" className="input_text" placeholder="search" />
          <FaSearch size={24} color="blue" />
        </div>
      </div>
    </div>
  );
};

export default Solutions;
