import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import MenuItems from "../menuitem/MenuItems";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [technologies, setTechnologies] = useState(false);
  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = isMobile ? "hidden" : "auto";
    }
  }, [isMobile]);
  return (
    <nav className="navbar">
      <h3 className="logo">MASA</h3>
      <ul className={isMobile ? "nav-links-mobile" : "nav-links"}>
        <Link
          to="/"
          className="home"
          onClick={() => {
            setIsMobile(false);
            setDropdown(false);
            setTechnologies(false);
          }}
        >
          <li> Home</li>
        </Link>

        <Link
          to="/Aboutus"
          className="Aboutus"
          onClick={() => {
            setIsMobile(false);
            setDropdown(false);
            setTechnologies(false);
          }}
        >
          <li> AboutUs</li>
        </Link>

        <Link
          to="/products"
          className="products"
          onClick={() => {
            setIsMobile(false);
            setDropdown(false);
            setTechnologies(false);
          }}
        >
          <li> Products</li>
        </Link>

        <Link
          to="/services"
          className="services"
          onClick={() => {
            setIsMobile(false);
            setDropdown(false);
            setTechnologies(false);
          }}
        >
          <li> Services</li>
        </Link>

        <Link
          onClick={() => {
            setDropdown(!dropdown);
            setTechnologies(false);
          }}
          // to="/solutions"
          className="solutions"
        >
          <li>
            Solutions
            {dropdown ? (
              <i className="fas fa-caret-up" />
            ) : (
              <i className="fas fa-caret-down" />
            )}
            {dropdown && (
              <div className="dropdown-content">
                {MenuItems.map((item, index) => {
                  return (
                    <li key={index}>
                      <Link
                        className="menu-items"
                        to={item.path}
                        onClick={() => setIsMobile(false)}
                      >
                        {item.title}
                      </Link>
                    </li>
                  );
                })}
              </div>
            )}
          </li>
        </Link>
        <Link
          className="dropButton"
          onClick={() => {
            setTechnologies(!technologies);
            setDropdown(false);
          }}
        >
          <li>
            Technologies
            {technologies ? (
              <i className="fa fa-caret-up " />
            ) : (
              <i className="fa fa-caret-down " />
            )}
          </li>
          {technologies && (
            <div className="dropdown-content ">
              <a href="/" onClick={() => setIsMobile(false)}>
                C++
              </a>
              <a href="/" onClick={() => setIsMobile(false)}>
                Node.js
              </a>
              <a href="/" onClick={() => setIsMobile(false)}>
                Javascript
              </a>
              <a href="/" onClick={() => setIsMobile(false)}>
                ReactNative
              </a>
              <a href="/" onClick={() => setIsMobile(false)}>
                Mobile
              </a>
              <a href="/" onClick={() => setIsMobile(false)}>
                Cloud
              </a>
              <a href="/" onClick={() => setIsMobile(false)}>
                Software As A Service(SaaS)
              </a>
              <a href="/" onClick={() => setIsMobile(false)}>
                BigData
              </a>
              <a href="/" onClick={() => setIsMobile(false)}>
                Data Science
              </a>
              <a href="/" onClick={() => setIsMobile(false)}>
                Macine learning
              </a>
              <a href="/" onClick={() => setIsMobile(false)}>
                Artifical intelligence
              </a>
              <a href="/" onClick={() => setIsMobile(false)}>
                BlockChain
              </a>
              <a href="/" onClick={() => setIsMobile(false)}>
                Azure
              </a>
            </div>
          )}
        </Link>

        <Link
          to="/contactus"
          className="contactus"
          onClick={() => {
            setIsMobile(false);
            setDropdown(false);
            setTechnologies(false);
          }}
        >
          <li> ContactUs</li>
        </Link>
      </ul>
      <button
        className="mobile-menu-icon"
        onClick={() => setIsMobile(!isMobile)}
      >
        {isMobile ? (
          <i className="fas fa-times"></i>
        ) : (
          <i className="fas fa-bars"></i>
        )}
      </button>
    </nav>
  );
};

export default Navbar;
