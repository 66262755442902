import React from "react";
import "./HealthManagement.css";

const HealthManagement = () => {
  return (
    <div className="health_container_main">
      <div className="health_container">
        <h1>HEALTH CARE MANAGEMENT SYSTEM</h1>
      </div>
      <p className="healthmanagement_paragraph">
        Healthcare management is the dynamic orchestration of healthcare
        services, encompassing the planning, coordination, and supervision of
        diverse facets within healthcare organizations. It involves a
        multifaceted approach, steering the operational, financial, and human
        elements to optimize patient care delivery. From strategizing long-term
        goals to ensuring day-to-day efficiency, healthcare managers navigate
        intricate regulatory landscapes, harness evolving technologies, and
        foster a skilled workforce. Their focus spans quality assurance,
        financial viability, community engagement, and compliance, all aimed at
        the overarching goal of providing accessible, high-quality healthcare
        while adapting to the ever-evolving landscape of healthcare policies and
        advancements. This includes patient information, billing, finance &
        Accounts, Staffing, Pharmacy, etc. The healthcare system serves the
        fundam
      </p>
    </div>
  );
};

export default HealthManagement;
