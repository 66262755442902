import React from "react";
import "./Contactus.css";
import { FaFacebook } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { FaTwitter } from "react-icons/fa";

const Contactus = () => {
  return (
    <div className="contact_us_container">
      <div className="contact_hero_container">
        <h1>We would Love to hear from you</h1>
        <p>
          Contact us for Queries, Projects and much more, We are always at your
          fingertip.
        </p>
      </div>
      <div className="contact_us_grid_container">
        <div className="input_container">
          <input
            type="text"
            placeholder="Full Name"
            className="contact_input_text"
          />
          <input
            type="text"
            placeholder="Mobile Number"
            className="contact_input_text"
          />
          <input
            type="text"
            placeholder="Email"
            className="contact_input_text"
          />
          <input
            type="text"
            placeholder="Subject"
            className="contact_input_text"
          />
          <textarea placeholder="Your Message" className="contact_textarea" />
          <button className="contact_button">submit</button>
        </div>
        <div className="contact_us_social_container">
          <h1>Connect Us</h1>
          <div className="icons_social">
            <FaFacebook size={45} color="blue" />
            <IoLogoInstagram color="red" size={45} />
            <FaTwitter size={45} color="blue" />
          </div>
          <h2>Our Social network sites</h2>
        </div>
      </div>
      <div className="office_container">
        <div className="our_office_section">
          <h4>hyderabad</h4>
          <p>5900 S. Lake Forest Drive, Suite 122</p>
          <p>McKinney, TX-75070</p>
          <p>9977999999</p>
        </div>
        <div className="our_office_section">
          <h4>bengaluru</h4>
          <p>5900 S. Lake Forest Drive</p>
          <p>near BigC,TX-75070</p>
          <p>8989898989</p>
        </div>
        <div className="our_office_section">
          <h4>chennai</h4>
          <p>5900 S. Lake Forest Drive, Suite 189</p>
          <p>opposite reliance mall, TX-75070</p>
          <p>7867899054</p>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
